/* Sidebar.css */

.sidebar {
  /* Update this color to match your desired sidebar color */
    /* background-color: #1f1f1f; */
    @apply bg-[#1f1f1f];
    color: #fff;
    width: 50px;
    transition: width 0.3s;
    /* overflow: hidden; */
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    /* height: 86vh; */
    /* overflow: auto; */
  /* position: fixed; */
  /* top: 0px; */
  }
  
  .sidebar.open {
    width: 400px;
  }
  
  .sidebar-header {
    padding: 0px;
    gap: 10px;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    flex-direction: column;
    height: fit-content;
  }
  
  .sidebar-icon {
    font-size: 24px;
    color: #fff;
    padding: 10px;
  }

  .sidebar-icon.highlighted {
    background-color: blue; /* Highlight color */
  }
  
  .sidebar-top {
    display: flex;
    flex-direction: column;
    align-items: center; /* This will center the divs horizontally */
    gap: 10px; /* This will add some space between the divs */
  }
  .sidebar-box {
    background-color: #333;
    color: #fff;
    border: none;
    padding: 5px 10px;
    border-radius: 5px;
    margin: 0 5px;
  }
  
  .sidebar-box:hover {
    background-color: #444;
  }
  
  .sidebar-content {
    flex-grow: 1;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }
  
  .nested-checkboxes {
    margin-left: 20px; /* Adjust as needed */
    display: flex;
    flex-direction: column; /* Makes sub-checkboxes vertical */
    margin-top: 5px;
  }
  
  .sidebar-bottom {
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .sidebar-bottom button {
    margin-bottom: 10px;
  }
  
  .sidebar-note {
    color: #ccc;
    font-size: 12px;
    margin-top: 10px;
    text-align: center;
  }
  