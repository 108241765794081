.login-input {
    padding: 12px;
    border-radius: 8px;
    border: 1px solid #555;
    font-size: 16px;
    width: 100%;
    transition: border-color 0.3s ease, box-shadow 0.3s ease;
  }
  
  .login-input.focused {
    border-color: #f44336;
    box-shadow: 0px 0px 8px rgba(244, 67, 54, 0.5);
  }
  